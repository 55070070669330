
import { isDark } from '@bkwld/light-or-dark'
import colors from 'assets/vars/colors.json'
export default

	props: block: Object

	# Get the index of the block
	inject: blockIndex: default: 0

	computed:

		# Is it a dark background color
		isDark: ->
			# If block has no background color, then default to false (light background)
			return false unless !!@block.backgroundColor or !!@hasBackgroundTint
			if @hasBackgroundTint then isDark colors[@block.backgroundTint]
			else isDark @block.backgroundColor || "white"

		# Root classes
		classes: -> 'dark-wrapper': @isDark

		# "Background Tint" is a feature that tweens the page background
		# to a new color when this block wrapper scrolls into view.
		hasBackgroundTint: -> !!@block.backgroundTint

		backgroundColor: ->
			return 'transparent' if @block.backgroundTint
			@block.backgroundColor

		backgroundTintHex: -> colors[@block.backgroundTint]

		backgroundStyle: -> background: @backgroundColor

		wrapperStyle: -> @backgroundStyle if !@block.arc

	mounted: ->
		# If block has backgroundTint then init the logic.  Otherwise, don't.
		return unless @hasBackgroundTint
		# Wait for child components to mount and render before setting up observer.
		# Otherwise all wrappers will be zero height and register as being in the viewport.
		@$wait 1000, => @setupObserver()
		window.addEventListener 'resize', @handleResize

	destroyed: ->	@destroyObserver?()

	methods:
		setupObserver: ->
			@wrapperObserver = new IntersectionObserver @handleObserved, {rootMargin: "-74% 0px -25% 0px", threshold:0}
			@wrapperObserver.observe @$el

		destroyObserver: -> if !!@$el then @wrapperObserver?.unobserve @$el

		handleObserved: (entries, observer) ->
			return unless entries?[0]?.isIntersecting && !!@block.backgroundTint
			el = document.querySelector('main > *')
			@$gsap.to el, 1.2, {backgroundColor: @backgroundTintHex }

		handleResize: ->
			@destroyObserver()
			@setupObserver()
